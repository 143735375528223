import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { sitesActions } from "../../redux/sites";
import clsx from "clsx";

const ResetFilters = () => {
	const { hasFilters } = useSelector((store) => store.sites);
	const dispatch = useDispatch();

	const reset = () => dispatch(sitesActions.resetFilters());

	return (
		<li onClick={reset} className={clsx("is-reset", hasFilters ? "is-visible" : "is-hidden")}>
			<span>Reset filters</span>
		</li>
	);
};

export default ResetFilters;
