import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../redux/ui";
import clsx from "clsx";
import { ThemeSwitch } from "./styles";

const DarkModeToggle = () => {
	const { isDarkMode } = useSelector((store) => store.ui);
	const dispatch = useDispatch();
	const toggleMode = () => dispatch(uiActions.setDarkMode(!isDarkMode));

	return (
		<ThemeSwitch
			className={clsx("dark-mode-toggle", isDarkMode ? "is-dark-mode" : "is-light-mode")}
			style={{ pointerEvents: "auto" }}
			onClick={toggleMode}
		>
			<span>Dark mode {clsx(isDarkMode ? "is enabled" : "is disabled")}</span>
			<i></i>
		</ThemeSwitch>
	);
};

export default DarkModeToggle;
