import styled from "styled-components";

export const Grid = styled.div`
	padding: 0 var(--page-padding);
	pointer-events: none;

	&.default {
		display: grid;
		grid-auto-flow: dense;
		grid-gap: 1rem 1rem;
		grid-template-columns: repeat(2, 1fr);

		@media screen and (min-width: 700px) {
			grid-gap: 3rem;
		}

		@media screen and (min-width: 1100px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: 1800px) {
			grid-gap: 4rem;
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.legacy {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 30px 0;
	}
`;
