import { newestToOldest, oldestToNewest, aToZ, zToA, randomize } from "./sort-fns";

export const initialSiteCount = 15;
export const mainColors = [
	"Black",
	"Grey",
	"White",
	"Blue",
	"Purple",
	"Red",
	"Pink",
	"Yellow",
	"Orange",
	"Brown",
	"Green",
];

export const sortOptions = [
	{
		label: "Newest to oldest",
		sortFn: newestToOldest,
	},
	{
		label: "Oldest to newest",
		sortFn: oldestToNewest,
	},
	{
		label: "A to Z",
		sortFn: aToZ,
	},
	{
		label: "Z to A",
		sortFn: zToA,
	},
	{
		label: "Randomize",
		sortFn: randomize,
	},
];
