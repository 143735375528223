import styled from "styled-components";

export const Colors = styled.div`
/*
	border: 4px solid var(--color-brand);
	margin: 0 0 2em;
	padding: 2em 2em calc(2em - 10px);
	*/
`;

export const ColorButton = styled.button`
	border: 4px solid var(--color-brand);
	border-radius: 50%;
	cursor: pointer;
	height: 100px;
	margin: 10px;
	outline: 0;
	position: relative;
	text-indent: -999em;
	transform: translateY(0);
	transition: box-shadow 0.3s cubic-bezier(0.65, -1, 0.25, 2.5),
		transform 0.3s cubic-bezier(0.65, -1, 0.25, 2.5);		;
	width: 100px;
	will-change: box-shadow, transform;

	&:hover {
		backface-visibility: hidden;
		box-shadow: -50px 50px 70px 0 rgba(0, 0, 0, 0.1);
		transform: translateY(-10%) scale(1.05);
	}

	&.active {
		backface-visibility: hidden;
		box-shadow: -50px 50px 70px 0 rgba(0, 0, 0, 0.1);
		transform: translateY(-10%) scale(1.05);

		&::before {
			background: var(--color-brand);
			border: 4px solid var(--color-brand);
			border-radius: 50%;
			content: "";
			display: block;
			height: 65px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 65px;
		}
	}
`;
