import styled from "styled-components";

export const Header = styled.header`
	display: block;
	left: 0;
	padding: 3vw var(--page-padding);
	pointer-events: none;
	position: sticky;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 1;


	a {
		display: inline-block;
		pointer-events: auto;
		z-index: 998;
	}

	img {
		display: block;
		margin: auto;
		max-width: 370px;
		max-height: 129px;
		width: 100%;
		height: 100%;
	}
`;
