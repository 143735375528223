import React, { Fragment, useEffect } from "react";
import { Route, Switch } from "react-router";
import GlobalStyle from "./utils/globalStyle";
import FrontPage from "./pages/FrontPage";
import GoSite from "./pages/GoSite";
import ColorPage from "./pages/ColorPage";
import TagPage from "./pages/TagPage";
import AboutPage from "./pages/AboutPage";
import Header from "./components/Header";
import SiteFooter from "./components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./redux/ui";
import { Transition, TransitionGroup } from "react-transition-group";
import { play, exit } from "./utils/timelines";

const visibleIcon = "/favicon.ico?v=1.1";
const notVisibleIcon = "/favicon-tab.ico?v=1.1";

const NotFound = () => (
	<div style={{ padding: "0 var(--page-padding)" }}>
		<h1>Nothing to see here 🥹 😭</h1>
	</div>
);

export default () => {
	const { isDarkMode, modeSet } = useSelector((store) => store.ui);
	const dispatch = useDispatch();

	useEffect(() => {
		if (typeof window !== "object") return;
		const isDarkByDefault = window.matchMedia("(prefers-color-scheme: dark)").matches;
		const isLightByDefault = window.matchMedia("(prefers-color-scheme: light)").matches;

		const isNotSpecifiedByDefault = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
		const hasNoSupport = !isDarkByDefault && !isLightByDefault && !isNotSpecifiedByDefault;

		const activateDarkMode = () => dispatch(uiActions.setDarkMode(true));
		const activateLightMode = () => dispatch(uiActions.setDarkMode(false));

		window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => e.matches && activateDarkMode());
		window.matchMedia("(prefers-color-scheme: light)").addListener((e) => e.matches && activateLightMode());

		if (!hasNoSupport && isDarkByDefault) {
			activateDarkMode();
		} else {
			activateLightMode();
		}
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const currentTitle = document.title;

			const changeFavicon = (url) => {
				let ic = document.querySelector("head [type='image/vnd.microsoft.icon']");
				if (!ic) {
					ic = document.createElement("link");
					document.head.appendChild(ic);
				}
				ic.rel = "icon";
				ic.href = url;
			};

			document.addEventListener("visibilitychange", (e) => {
				if (document.visibilityState !== "visible") {
					document.title = "😭 COME BACK 😭";
					changeFavicon(notVisibleIcon);
				} else {
					document.title = currentTitle;
					changeFavicon(visibleIcon);
				}
			});
		}
	}, []);

	const styleProps = typeof isDarkMode === "boolean" && modeSet ? { isDarkMode } : {};

	return (
		<Route
			render={({ location, ...restProps }) => {
				const { pathname, key } = location;
				return (
					<Fragment>
						<GlobalStyle {...styleProps} isModeSet={modeSet} />
						<Header />

						<TransitionGroup component={null}>
							<Transition
								key={key}
								appear={true}
								onEnter={(node, appears) => play(pathname, node, appears)}
								onExit={(node) => exit(node)}
								timeout={{ enter: 750, exit: 150 }}
							>
								<Switch location={location}>
									<Route exact={true} path="/" render={() => <FrontPage />} />
									<Route path="/go/:goUrl" render={() => <GoSite {...restProps} />} />
									<Route path="/color/:color" render={() => <ColorPage {...restProps} />} />
									<Route path="/tag/:tag" render={() => <TagPage {...restProps} />} />
									<Route path="/about" render={() => <AboutPage />} />
									<Route path="**" component={NotFound} />
								</Switch>
							</Transition>
						</TransitionGroup>

						<SiteFooter />
					</Fragment>
				);
			}}
		/>
	);
};
