import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SiteList from "../../components/SiteList";
import { Slogan } from "./styles";
import { Helmet } from "react-helmet-async";
import SiteGridFilters from "../../components/SiteGridFilters";

const FrontPageWrapper = styled.div`
	padding: 0 var(--page-padding);
	pointer-events: none;
	position: relative;
	z-index: 9;
`;

const FrontPage = () => {
	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.classList.add("animate");
		}
	}, [ref]);

	return (
		<>
			<Helmet>
				<title>CSSline - Showcase gallery of excellent sites.</title>
				<meta
					name="description"
					content="Explore CSSline, a global showcase gallery featuring the world's most excellent websites since 2009. Enjoy an ad-free browsing experience!"
				/>
			</Helmet>
			<FrontPageWrapper>
				<div role="region" aria-label="Heading of CSSline">
					{/* h1 needs to be inside "landmark" =  inside wrapper with role="something" */}
					<Slogan ref={ref}>
						CSS<span>line</span> is a showcase gallery of excellent sites <i>since</i> 2009.
					</Slogan>
				</div>

				<SiteGridFilters />

				<SiteList />
			</FrontPageWrapper>
		</>
	);
};

export default FrontPage;
