import React from "react";
import clsx from "clsx";
import { Grid } from "./styles";
import SiteBlock from "../../components/SiteBlock";

const SiteGrid = ({ sites = [], grid = "default", additionalClasses = [] }) => {
	if (!sites || sites.length === 0) {
		return <h2 style={{ padding: "0 var(--page-padding)" }}>No sites to show 😭 😭</h2>;
	}

	return (
		<Grid className={clsx("site-grid", grid, ...additionalClasses)} role="main">
			{sites.map((site) => {
				return <SiteBlock key={"stm" + site.goUrl} site={site} grid={grid} />;
			})}
		</Grid>
	);
};

export default SiteGrid;
