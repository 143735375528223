import { SET_DARK_MODE, SET_GRID } from "./types";

export const setDarkMode = (state) => ({
	type: SET_DARK_MODE,
	payload: { state },
});

export const setGrid = (grid) => ({
	type: SET_GRID,
	payload: { grid },
});
