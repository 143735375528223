import React, { useState } from "react";
import { Site, SiteContainer, SiteImage, SiteName } from "./styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { ensureHash } from "../../utils/common";

const SiteBlock = ({ site, grid = "default" }) => {
	if (!site) return null;
	const siteTags = Array.isArray(site.tags) ? site.tags : Object.values(site.tags);
	const isFeatured = site && siteTags.indexOf("featured") !== -1;
	const [active, setActive] = useState(false);

	const handleMouseEnter = () => {
		setActive(true);
	  };

	  const handleMouseOut = () => {
		setActive(false);
	  };

	return (
		<Site
      className={clsx("site-item", grid, isFeatured && "featured", active && "active")}
      style={{ backgroundColor: ensureHash(site?.colors?.[0]) }}
    >

			<SiteContainer>
				<Link to={`/go/${site.goUrl}`}>
					{/* <Link to={site.url}> */}
					<SiteImage>
						<picture>
							<source media="(max-width: 580px)" srcSet={site.thumbUrl} alt={site.title} />
							<source media="(min-width: 581px)" srcSet={site.screenshotUrl} alt={site.title} />
							<img loading="lazy" src={site.screenshotUrl} alt={site.title} height="243" width="433" />
						</picture>
					</SiteImage>
					<SiteName>
						{site.title}
					</SiteName>
				</Link>
			</SiteContainer>
			<a className="info" href={site?.url + "?ref=cssline.com"} target="_blank" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
			Visit
			</a>
		</Site>
	);
};

export default SiteBlock;
