import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { sortOptions } from "../../utils/config";
import { sitesActions } from "../../redux/sites";
import clsx from "clsx";
import { SortContainer, SortButton } from "./styles";

const SortSelect = () => {
	const dispatch = useDispatch();
	const { sort } = useSelector((store) => store.sites);

	const setSort = ({ label }) => {
		dispatch(sitesActions.toggleSort(label));
	};

	const isActive = (option) => {
		return option.label === sort;
	};

	return (
		<SortContainer>
			{sortOptions.map((option) => (
				<SortButton
					key={option.label.replace(/\w/, "")}
					className={clsx(isActive(option) && "is-active")}
					onClick={() => setSort(option)}
				>
					{option.label}
				</SortButton>
			))}
		</SortContainer>
	);
};

export default SortSelect;
