import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../redux/ui";
import { GridSelectWrapper } from "./styles";

const GridSelect = () => {
	const dispatch = useDispatch();
	const { grid } = useSelector((store) => store.ui);
	const setGrid = () => {
		const style = grid === "cards" ? "default" : "cards";
		dispatch(uiActions.setGrid(style));
	};

	return (
		<GridSelectWrapper className={grid} onClick={setGrid}>
			<span>Special hover is {grid === "cards" ? "enabled" : "disabled"}</span>
			<i></i>
		</GridSelectWrapper>
	);
};

export default GridSelect;
