import React from "react";
import logo from "./logo_cssline.png";
import { Header as SiteHeader } from "./styles";
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<SiteHeader role="banner">
			<Link to="/">
				<img src={logo} alt="CSSline" width="370" height="129" />
			</Link>
		</SiteHeader>
	);
};

export default Header;
