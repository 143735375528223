import React from "react";
import { Link } from "react-router-dom";
import { Footer, FooterLinks } from "./styles";

const SiteFooter = () => {
	const year = new Date().getFullYear();

	return (
		<Footer role="contentinfo">
			<FooterLinks>
				<li><Link to="/about" className="link">About CSSline</Link></li>
				<li><a href="https://x.com/intent/tweet?url=https%3A%2F%2FYourUrlHere.com&amp;related=cssline,virlander,tsuvinen&amp;text=I%20suggest%20YOURSITENAME%20for%20@CSSline&amp;hashtags=cssline,showcase" className="link" target="_blank" rel="noreferrer noopener">Submit a site</a></li>
				{/*<li><a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=Z2H399K6K27YE" className="link" target="_blank">Donate to keep us ad free!</a></li>*/}
				<li><a href="https://www.facebook.com/CSSline" className="link" target="_blank" rel="noreferrer noopener">Facebook</a></li>
				<li><a href="https://www.x.com/CSSline" className="link" target="_blank" rel="noreferrer noopener">X</a></li>
			</FooterLinks>
			<p>&copy; 2009 - {year} CSSline.com &mdash; Made in <span>🇫🇮</span> by <a href="https://www.instagram.com/teemumeet/" className="link" target="_blank" rel="noreferrer noopener">@teemumeet</a> &amp;{" "} <a href="https://www.instagram.com/virlander/" className="link" target="_blank" rel="noreferrer noopener">@virlander</a></p>
		</Footer>
	);
};

export default SiteFooter;
