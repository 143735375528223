import styled, { css, keyframes } from "styled-components";


export const Info = styled.div`
	margin: 2em 0 4em;
	padding: 0 calc(10px + 3%);
	position: relative;
	z-index: 2;

	h1 {
		font-size: 1.8em;
		font-size: calc(14px + (30 - 14) * ((100vw - 320px) / (1200 - 320))) !important;
		font-weight: 400;
		line-height: 1.3;

		i {
			font-weight: bold;
		}

		@media (min-width: 1200px) {
			font-size: 30px !important;
		}
	}

	a {
		font-size: 1.6em;
		font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1200 - 320))) !important;
		margin: 0 0 1em;

		@media (min-width: 1200px) {
			font-size: 18px !important;
		}
	}

	.marquee {
		display: none;
		left: 0;
		mix-blend-mode: color-burn;
		overflow: hidden;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100vw;

		@media (min-width: 900px) {
			display: block;
		}

		.marquee__inner {
			animation: marquee 5s linear infinite;
			animation-play-state: paused;
			display: flex;
			opacity: 0;
			position: relative;
			transform: translate3d(calc(-25% + 20vw), 0, 0);
			transition: opacity 0.1s;
			width: fit-content;

			span {
				display: inline-block;
				font-size: 10vw;
				font-style: italic;
				font-weight: 900;
				line-height: 1.15;
				padding: 0 3vw;
				white-space: nowrap;
			}
		}
	}

	a:hover ~ .marquee .marquee__inner {
		animation-play-state: running;
		opacity: 1;
		transition-duration: 0.4s;
	}

	@keyframes marquee {
		0% {
			transform: translate3d(calc(-25% + 20vw), 0, 0);
		}

		100% {
			transform: translate3d(calc(-50% + 20vw), 0, 0);
		}
	}

`;
