import React, { useState, useRef } from "react";
import { GridNav, GridSettings } from "./styles";
import useOnClickOutside from "../../hooks/use-onclick-outside";
import mapValues from "lodash/mapValues";
import MainColors from "../MainColors";
import TagList from "../TagList";
import DarkModeToggle from "../../components/DarkModeToggle";
import SortSelect from "../../components/SortSelect";
import GridSelect from "../../components/GridSelect";
import ResetFilters from "../../components/ResetFilters";

const SiteGridFilters = () => {
	const ref = useRef(null);
	const [openState, setOpenState] = useState({
		colors: false,
		tags: false,
		order: false,
		settings: false,
	});

	const closeAll = () => {
		const newState = mapValues(openState, () => false);
		setOpenState(newState);
	};

	const toggleOpen = (key) => {
		const newState = mapValues(openState, () => false);
		setOpenState({
			...newState,
			[key]: !openState[key],
		});
	};

	useOnClickOutside(ref, () => closeAll());

	return (
		<div ref={ref} style={{ pointerEvents: "auto" }}>
			<GridNav>
				<ResetFilters />
				<li onClick={() => toggleOpen("colors")} className={openState["colors"] ? "is-open" : "is-closed"}>
					<span>Colors</span>
				</li>
				<li onClick={() => toggleOpen("tags")} className={openState["tags"] ? "is-open" : "is-closed"}>
					<span>Tags</span>
				</li>
				<li onClick={() => toggleOpen("order")} className={openState["order"] ? "is-open" : "is-closed"}>
					<span>Order</span>
				</li>
				<li onClick={() => toggleOpen("settings")} className={openState["settings"] ? "is-open" : "is-closed"}>
					<span>Settings</span>
				</li>
			</GridNav>
			{openState.colors && (
				<GridSettings>
					<MainColors />
				</GridSettings>
			)}
			{openState.tags && (
				<GridSettings>
					<TagList />
				</GridSettings>
			)}
			{openState.order && (
				<GridSettings>
					<SortSelect />
				</GridSettings>
			)}
			{openState.settings && (
				<GridSettings>
					<DarkModeToggle />
					<GridSelect />
				</GridSettings>
			)}
		</div>
	);
};

export default SiteGridFilters;
