import styled, { css } from "styled-components";

export const Site = styled.article`
	pointer-events: all;

	&.default {
		backface-visibility: hidden !important;
		box-shadow: -30px 30px 50px 0 rgba(0, 0, 0, 0.1);
		display: block;
		grid-column: span 1;
		grid-row: span 1;
		height: auto;
		/*margin: 0 0 4%;*/
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
		text-align: center;
		transform: translateY(0);
		transition: box-shadow 0.3s /* cubic-bezier(0.65, -1, 0.25, 2.5) */,
			transform 0.3s /* cubic-bezier(0.65, -1, 0.25, 2.5) */;
		width: auto;
		will-change: box-shadow, transform;
		z-index: 10;

		&:hover, &:focus, &.active {
			backface-visibility: hidden;
			box-shadow: -50px 50px 70px 0 rgba(0, 0, 0, 0.1);
			transform: translateY(-2%) scale(1.02);

			span {
				opacity: 1;
				transform: translateY(-50%);
			}

			a:first-child {
				opacity: 1;
				transform: translateY(160%);
			}
		}

		@media (min-width: 560px) {
			margin-bottom: 0;
		}
	}
	/* &:nth-child(12n) */
	/*&.default.featured,
	&.default:nth-child(1),
	&.default[class*="featured"] */
	&.default:nth-child(1),
	&.default:nth-child(12n),
	&.default.featured
	{
		grid-column: span 2;
		grid-row: span 2;

		span {
			font-size: 4vw;

			@media screen and (min-width: 1100px) {
				font-size: 2.9vw;
			}
		}
	}

	a.info {
		display: none;

		@media (min-width: 600px) {
			/*background-color: hsla(195,100%,47%,.2);
			background-color: rgba(0,0,0,.8);
			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
				-webkit-backdrop-filter: blur(20px);
				backdrop-filter: blur(20px);
				background-color: rgba(0,0,0,.4);
			}*/
			background-color: var(--color-brand);
			border-radius: 50px;
			display: block;
			font-size: 14px;
			inset-block-end: .5rem;
			inset-inline-end: .5rem;
			opacity: 0;
			padding: .3rem .6rem;
			position: absolute;
			text-decoration: none;
			text-transform: lowercase;
			transition: all .5s;
			z-index: 300;
		}
	}

	&:hover {
		a.info {
			opacity: 1;
		}
	}
`;

export const SiteContainer = styled.div`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;

	a:first-of-type {

		&::after {
			background-color: var(--color-brand);
			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
				-webkit-backdrop-filter: blur(20px);
				backdrop-filter: blur(20px);
				background-color: hsla(195,100%,47%,.3);
			}
			bottom: 0;
			color: currentColor;
			content: '';
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity .5s;
			z-index: 1;
		}

		&:hover, &:focus, .active & {
			&::after {
				opacity: 1;
			}
		}
	}
`;

export const SiteImage = styled.div`
	bottom: 0;
	display: block;
	height: 100%;
	left: 0;
	overflow: hidden;
	padding-bottom: 56.25%; /*58.3%;*/
	position: relative;
	right: 0;
	top: 0;
	width: 100%;

	img {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;

		&[src=""] {
			display: none;
		}
	}
`;

export const SiteName = styled.span`
	color: #fff;
	display: block;
	font-size: 4vw;
	left: 0;
	letter-spacing: .08em;
	line-height: 1.28em;
	opacity: 0;
	padding: 0 40px;
	position: absolute;
	top: 50%;
	transform: translateY(0%);
	transition: transform .5s, opacity .5s;
	transition-delay: .1s;
	width: 100%;
	z-index: 2;

	@media screen and (min-width: 730px) {
		font-size: 3vw;
	}

	@media screen and (min-width: 900px) {
		font-size: 3vw;
	}

	@media screen and (min-width: 1100px) {
		font-size: 1.9vw;
	}

	@media screen and (min-width: 1800px) {
		font-size: 1.5vw;
	}

`;
