import React, { useEffect, useRef } from "react";
import { Site, SiteImageContainer, SiteImage, SiteImagePerspective, SiteName, Shine } from "./styles";
// import clsx from "clsx";
import { Link } from "react-router-dom";
import { ensureHash } from "../../utils/common";

const SiteBlock = ({ site }) => {
	const ref = useRef(null);
	if (!site) return null;
	// const siteTags = Array.isArray(site.tags) ? site.tags : Object.values(site.tags);
	// const isFeatured = site && siteTags.indexOf("featured") !== -1;

	useEffect(() => {
		if (!ref.current) return;

		const onHover = (image, { offsetX, offsetY }) => {
			const width = image.clientWidth;
			const height = image.clientHeight;
			const dx = offsetX - width / 2;
			const dy = offsetY - height / 2;

			const tx = dx / 16;
			const ty = dy / 16;

			[
				["--dx", dx],
				["--dy", dy],
				["--tx", tx],
				["--ty", ty],
			].forEach(([property, value]) => {
				ref.current.style.setProperty(property, String(value));
			});
		};

		const reset = () => {
			["--dx", "--dy", "--tx", "--ty"].forEach((property) => {
				ref.current.style.setProperty(property, "0");
			});
		};

		const image = ref.current.querySelector(".image");

		ref.current.addEventListener("mousemove", (e) => onHover(image, e));
		ref.current.addEventListener("mouseout", () => reset(image));

		return () => {
			ref.current.removeEventListener("mousemove", (e) => onHover(image, e));
			ref.current.removeEventListener("mouseout", () => reset(image));
		};
	}, [ref]);

	return (
		<Site ref={ref}>
			<SiteImageContainer className="site-image-container">
				<Link to={`/go/${site.goUrl}`}>
					<SiteImagePerspective>
						<SiteImage className="image" background={ensureHash(site?.colors?.[0])} image={site?.thumbUrl}>
							<Shine className="shine" />
							<SiteName>{site?.title}</SiteName>
						</SiteImage>
					</SiteImagePerspective>
				</Link>
			</SiteImageContainer>
		</Site>
	);
};

export default SiteBlock;
