import styled from "styled-components";

export const LoadMoreContainer = styled.div`
	margin: 60px 0;
	text-align: center;
`;

export const LoadButton = styled.button`
	background: transparent;
	color: var(--color-brand);
	border: 2px solid var(--color-brand);
	font-size: 16px;
	padding: 20px;
	pointer-events: auto;
	position: relative;
	text-decoration: none;
	transform: translateY(0);
	transition: box-shadow 0.3s /* cubic-bezier(0.65, -1, 0.25, 2.5) */,
		transform 0.3s /* cubic-bezier(0.65, -1, 0.25, 2.5) */;
	will-change: box-shadow, transform;

	&:hover {
		backface-visibility: hidden;
		box-shadow: -50px 50px 70px 0 rgba(0, 0, 0, 0.1);
		transform: translateY(-10%) scale(1.05);
	}
`;
