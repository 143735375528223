import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Grid } from "./styles";
import SiteBlock from "../SiteBlock";
import SiteHoverCard from "../SiteHoverCard";
import LoadMore from "../../components/LoadMore";
import { sortOptions } from "../../utils/config";

const getSiteComponent = (grid) => {
	switch (grid) {
		case "cards":
			return SiteHoverCard;
		case "default":
		default:
			return SiteBlock;
	}
};

const SiteList = () => {
	try {
		const { all = [], hasFilters, filteredSites = [], showCount, sort } = useSelector((store) => store.sites);
		const { grid } = useSelector((store) => store.ui);

		const sites = hasFilters ? filteredSites : all;

		const { sortFn } = sortOptions.find((option) => option.label === sort) || {};

		if (!sites || sites.length === 0) {
			return <h2 style={{ padding: "0 var(--page-padding)" }}>No sites to show 🥹 😭</h2>;
		}

		const sortedSites = [...sites].sort(sortFn);
		const sitesToShow = sortedSites.slice(0, showCount);

		const SiteComponent = getSiteComponent(grid);

		return (
			<>
				<Grid className={clsx("site-grid", grid)} role="main">
					{sitesToShow.map((site) => (
						<SiteComponent key={"stm" + site.goUrl} site={site} grid={grid} />
					))}
				</Grid>
				{sitesToShow.length !== 0 && <LoadMore />}
			</>
		);
	} catch (e) {
		console.log(e);
		return null;
	}
};

export default SiteList;
