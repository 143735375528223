import React, { Component } from "react";

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log({ error, errorInfo });
	}

	render() {
		const { hasError } = this.state;
		const { fallback, children } = this.props;

		return hasError ? fallback || null : children;
	}
}
