import React from "react";
import { Tags, TagButton } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { sitesActions } from "../../redux/sites";
import clsx from "clsx";

const Tag = ({ onClick, tag, isActive = false }) => {
	return (
		<TagButton onClick={onClick} className={clsx("tag-button", isActive && "is-active")}>
			{tag}
		</TagButton>
	);
};

const TagList = () => {
	const { tags, selectedTags } = useSelector((store) => store.sites);
	const dispatch = useDispatch();
	const toggleTag = (tag) => {
		dispatch(sitesActions.toggleTag(tag));
	};
	const isActiveTag = (tag) => !!selectedTags && selectedTags.includes(tag);

	return (
		<Tags role="search" aria-label="Tag search">
			{!!tags &&
				tags.length > 0 &&
				tags.map((tag, index) => (
					<Tag
						tag={tag}
						key={tag.replace(/\s/, "") + index}
						onClick={() => toggleTag(tag)}
						isActive={isActiveTag(tag)}
					/>
				))}
		</Tags>
	);
};

export default TagList;
