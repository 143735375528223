import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { sites } from "./sites";
import { ui } from "./ui";
const hasWindow = typeof window !== "undefined" && window.document;

const reducers = (history) =>
	combineReducers({
		router: hasWindow ? connectRouter(history) : () => ({}),
		sites,
		ui,
	});

export default reducers;
