import styled, { css, keyframes } from "styled-components";

const blockEffect = keyframes`
	0% {
		transform: scale3d(0, 1, 1);
	}
	50% {
		transform-origin: left center;
	}
	51% {
		transform: scale3d(1, 1, 1);
		transform-origin: right center;
	}
	100% {
		transform: scale3d(0, 1, 1);
		transform-origin: right center;
	}
`;

export const Slogan = styled.h1`
	color: transparent;
	font-size: 1.8em;
	font-size: calc(14px + (36 - 14) * ((100vw - 320px) / (1200 - 320)));
	font-weight: 400;
	line-height: 1.3;
	margin: 2em 0;
	max-width: 22em;
	pointer-events: all;
	position: relative;

	@media (min-width: 1200px) {
		font-size: 36px !important;
	}

	/*
	span {
		display: inline-block;
		line-height: 0;
		visibility: hidden;

		&::after {
			border-bottom: 2px solid #000;
			content: "";
			display: inline-block;
			visibility: visible;
			width: 100%;
		}
	}
	*/

	&::before {
		background-color: var(--color-slogan-effect);
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transform: scale3d(0, 1, 1);
		transform-origin: left center;
		width: 100%;
		z-index: 1;
	}

	&::after {
		background: var(--color-bg);
		bottom: -0.25rem;
		content: "";
		left: -0.25rem;
		position: absolute;
		right: -0.25rem;
		top: -0.25rem;
		transform-origin: top right;
		transform: scale(1, 1);
		transition-delay: 0.1s;
		transition: transform 0.9s cubic-bezier(0.84, -0.01, 0.48, 0.995);
		z-index: 3;
	}

	&.animate {
		color: inherit;
		transition: color cubic-bezier(0.42, 0, 0.58, 1) 0.38s;

		&::before {
			animation: 0.76s cubic-bezier(0.475, 0.425, 0, 0.995) ${blockEffect};
		}

		&::after {
			transform: scale(0, 1);
			transition: transform 0.9s cubic-bezier(0.84, -0.01, 0.48, 0.995);
		}
	}
`;
