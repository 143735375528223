import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { useSelector } from "react-redux";
import SiteGrid from "../../components/SiteGrid";
import { Info } from "./styles";
import { Link } from "react-router-dom";
import { newestToOldest } from "../../utils/sort-fns";

const TagPage = (props) => {
	const tag = get(props, ["match", "params", "tag"]);
	const { all: sites } = useSelector((store) => store.sites);
	const tagSites = sites.filter((site) => site?.tags?.includes(tag));
	const sortedTagSites = tagSites.sort(newestToOldest);

	useEffect(() => {
		try {
			const scroll = get(props, "location.state.scroll");

			if (scroll && !!window) {
				window.scrollTo(0, 0);

				props.history.replace({
					pathname: props.location.pathname,
					state: { scroll: false },
				});
			}
		} catch (err) {
			console.warn(err);
		}
	}, []);

	{/* <div style={{ padding: "0 calc(10px + 3%)" }}> */}

	return (
		<div>
			<Helmet>
				<title>Sites tagged with {tag} - CSSline</title>
			</Helmet>

			<Info>
				<h1>
					Sites tagged with <i>{tag}</i> 👍
				</h1>
				<Link to="/" className="link">Go to Index</Link>
				<div className="marquee">
					<div className="marquee__inner" aria-hidden="true">
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
					</div>
				</div>
			</Info>

			<SiteGrid sites={sortedTagSites} />
		</div>
	);
};

export default withRouter(TagPage);
