import styled from "styled-components";

export const Grid = styled.div`
	&.default {
		display: grid;
		grid-auto-flow: dense;
		grid-gap: 1rem 1rem;
		grid-template-columns: repeat(2, 1fr);

		@media screen and (min-width: 700px) {
			grid-gap: 3rem;
		}

		@media screen and (min-width: 1100px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: 1800px) {
			grid-gap: 4rem;
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.cards {
		display: grid;
		grid-gap: 1.5rem;
		grid-template-columns: repeat(1, 1fr);

		@media (min-width: 490px) {
			grid-gap: 2rem;
			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: 991px) {
			grid-gap: 3rem;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.legacy {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 30px 0;
	}
`;
