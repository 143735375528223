import { createGlobalStyle, css } from "styled-components";

const defaultColors = css`
	--color-bg: hsl(240, 4%, 95%);
	--color-brand: hsl(195, 100%, 47%);
	--color-text: hsl(200, 19%, 15%);
	--color-link: hsl(200, 19%, 15%);

	--color-slogan-effect: var(--color-brand);

	--color-grid-nav-filters-border: hsl(0, 0%, 1%);
	--color-grid-nav-filters-bg: hsl(240, 4%, 95%);
	--color-dark-mode-toggle: hsl(0, 100%, 0%);

	--color-go-page-color-border: hsl(0, 0%, 87%);
	--color-go-page-color-shadow-hover: 0 10px 16px -4px rgba(36, 45, 50, 0.3);

	--color-tag-button-bg: var(--color-bg);
	--color-tag-button-bg-hover: var(--color-brand);
	--color-tag-button-border: hsl(200, 19%, 15%);
	--color-tag-button-border-hover: var(--color-brand);
	--color-tag-button-text: hsl(200, 19%, 15%);
	--color-tag-button-text-hover: hsl(240, 4%, 95%);
`;

const darkModeColors = css`
	--color-bg: hsl(0, 0%, 1%);
	--color-text: hsl(240, 4%, 95%);
	--color-link: hsl(240, 4%, 95%);

	--color-slogan-effect: var(--color-bg);

	--color-go-page-color-shadow-hover: 0 10px 16px -4px rgba(255, 255, 255, 0.3);

	--color-grid-nav-filters-border: hsl(240, 4%, 95%);
	--color-grid-nav-filters-bg: hsl(0, 0%, 1%);
	--color-dark-mode-toggle: hsl(0, 100%, 100%);

	--color-tag-button-bg: var(--color-bg);
	--color-tag-button-bg-hover: var(--color-brand);
	--color-tag-button-border: hsl(240, 4%, 95%);
	--color-tag-button-border-hover: var(--color-brand);
	--color-tag-button-text: hsl(240, 4%, 95%);
	--color-tag-button-text-hover: hsl(240, 4%, 95%);
`;

const globalStyle = createGlobalStyle`
	/*@import url('https://fonts.googleapis.com/css?family=Cousine&display=swap');*/
	@import url('https://fonts.googleapis.com/css?family=Fira+Code&display=swap');

	:root {
		${(props) => (props.isDarkMode && props.isModeSet ? darkModeColors : defaultColors)}
		--page-padding: calc(10px + 3%);
	}

	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	html {
		font-size: calc(112.5% + 0.5vw);
	}

	html,
	body {
		background: var(--color-bg);
		color: var(--color-text);
		font-family: "Fira Code", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
		margin: 0;
		min-height: 100%;
		/* overflow-x: hidden; */
		padding: 0;
		-webkit-tap-highlight-color: #00b3f0;
		-webkit-tap-highlight-color: var(--color-brand);
		-webkit-font-feature-settings: "calt" 1;
		font-feature-settings: "calt" 1;
		-webkit-font-variant-ligatures: contextual;
		font-variant-ligatures: contextual;
	}

	a {
		color: var(--color-link);
		text-decoration: underline;
		text-decoration-style: solid;
		text-decoration-thickness: 2px;
		text-underline-offset: 1px;

		&:hover {
			text-decoration-color: var(--color-brand);
			text-decoration-style: wavy;
		}

		&.ellipsis {
			/*
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			word-break: break-all;
			word-break: break-word;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			-webkit-hyphens: auto;
			hyphens: auto;
			*/
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
		}
	}

	.link {
		/*border-bottom: 2px solid var(--color-grid-nav-filters-border);*/
		display: inline-block;
		padding: 0 0 0px;
		position: relative;
		/*text-decoration: none;*/

		&:hover {
			border-color: var(--color-brand);

		}
	}

	#root {
		display: block;
		/*padding: 0 calc(10px + 3%);*/
		position: relative;
	}

	button {
		cursor: pointer;
		font-family: inherit;
		outline: 0;
	}

	.js-events {
		pointer-events: auto;
	}

	.load-start > * {
		transition: opacity 150ms;
		opacity: 0;
	}

	.loaded > * {
		transition: opacity 150ms;
		opacity: 1;
	}
`;

export default globalStyle;
