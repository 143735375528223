import styled, { css } from "styled-components";

export const GridSelectWrapper = styled.div`


	@media (min-width: 700px) {
		display: flex;
		justify-content: space-between;
		padding: 0 0 0.5em;
	}

	@media (hover: none) {
		display: none;
	}

	i {
		background: #d8d9db;
		border-radius: 13px;
		box-sizing: initial;
		display: inline-block;
		height: 20px;
		margin: 0 0 0 1em;
		min-width: 40px;
		padding: 2px;
		position: relative;
		transition: 0.25s 0.09s;
		vertical-align: middle;
		width: 40px;

		&:after {
			background: #fff;
			border-radius: 50%;
			content: " ";
			display: block;
			height: 20px;
			left: 2px;
			min-width: 20px;
			position: absolute;
			transition: 0.25s;
			width: 20px;
		}
	}

	&.cards i {
		background: #4bd865;
		background: var(--color-brand);
	}

	&.default i {
		background: repeating-radial-gradient() !important;
	}

	&.cards i:after {
		transform: translateX(20px);
	}

	&.default i:after {
		transform: translateX(0px);
	}

	&:hover {
		cursor: pointer;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}
`;
