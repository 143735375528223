import { SET_DARK_MODE, SET_GRID } from "./types";

const initialState = {
	isDarkMode: undefined,
	modeSet: false,
	grid: "default",
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case SET_DARK_MODE:
			return {
				...state,
				modeSet: true,
				isDarkMode: payload.state,
			};

		case SET_GRID:
			return {
				...state,
				grid: payload.grid,
			};

		default:
			return state;
	}
}
