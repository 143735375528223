import anime from "animejs";

const getHomeTimeline = (node, delay) => {
	const timeline = anime.timeline({
		autoplay: false,
		easing: "linear",
		duration: 500,
		delay,
	});

	timeline.add({
		targets: node,
		opacity: [0, 1],
	});

	return timeline;
};

export const play = (pathname, node, appears) => {
	const delay = appears ? 0 : 250;
	let timeline;

	switch (pathname) {
		case "/":
		default:
			timeline = getHomeTimeline(node, delay);
	}

	timeline.play();
};

export const exit = (node) => {
	const timeline = anime.timeline({ autoplay: false, duration: 500 });

	timeline.add({ targets: node, opacity: [1, 0], easing: "linear" });
	timeline.play();
};
