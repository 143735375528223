import styled from "styled-components";

export const Tags = styled.div`
/*
	border: 4px solid var(--color-brand);
	margin: 0 0 2em;
	padding: 2em 2em calc(2em - 10px);
	*/
`;

export const TagButton = styled.button`
	background: var(--color-tag-button-bg);
	border: 2px solid var(--color-tag-button-border);
	border-radius: 0.125rem;
	box-shadow: 1px 1px 0 var(--color-tag-button-border), 2px 2px 0 var(--color-tag-button-border);
	cursor: pointer;
	color: var(--color-tag-button-text);
	display: inline-table;
	font-size: 0.75em;
	margin-bottom: 10px;
	padding: 0.2rem 0.5rem;
	text-decoration: none;
	transition: transform 0.1s;
	white-space: nowrap;

	&:not(:last-of-type) {
		margin-right: 10px;
	}

	&:hover {
		box-shadow: 1px 1px 0 var(--color-tag-button-border), 1px 1px 0 var(--color-tag-button-border);
		transform: translateY(2px);
	}

	&.is-active {
		background: var(--color-grid-nav-filters-bg);
		border: 2px solid var(--color-grid-nav-filters-bg);
		box-shadow: 0 0 0 var(--color-tag-button-border), 0 0 0 var(--color-tag-button-border);
		transform: translateY(2px);
	}
`;
