import { LOAD_MORE, RESET_FILTERS, TOGGLE_MAIN_COLOR, TOGGLE_SORT, TOGGLE_TAG } from "./types";
import { initialSiteCount, sortOptions } from "../../utils/config";

const initialState = {
	all: [],
	tags: [],
	filteredSites: [],
	selectedTags: [],
	showCount: initialSiteCount,
	showTotal: null,
	hasFilters: false,
	sort: sortOptions[0].label,
	$$loading: false,
	$$error: null,
};

const isSameIgnoreCase = (a, b) => {
	const lowerA = a.toLowerCase();
	const lowerB = b.toLowerCase();

	return lowerA === lowerB;
};

const hasFilters = (state) => {
	return state?.selectedTags?.length > 0 || state?.selectedMainColor;
};

const getFilteredSites = (state) => {
	const { selectedTags, selectedMainColor, all: sites } = state;

	const filteredByTags = selectedTags?.length
		? sites.filter((site) => selectedTags.every((tag) => site.tags?.includes(tag)))
		: sites;

	const filteredByMainColor = selectedMainColor
		? filteredByTags.filter((site) => isSameIgnoreCase(site?.mainColor, selectedMainColor))
		: filteredByTags;

	const filtered = filteredByMainColor;

	return filtered;
};

const isShowingAll = (siteCount) => {
	return siteCount <= initialSiteCount;
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case TOGGLE_TAG: {
			const { selectedTags: stateSelectedTags = [], filteredSites: currentFilteredSites } = state;
			let selectedTags;

			if (stateSelectedTags.includes(payload.tag)) {
				selectedTags = stateSelectedTags.filter((tag) => tag !== payload.tag);
			} else {
				selectedTags = [...stateSelectedTags, payload.tag];
			}

			const newState = {
				...state,
				selectedTags,
			};

			const filteredSites = getFilteredSites(newState);

			return {
				...newState,
				allVisible: isShowingAll(filteredSites.length),
				filteredSites,
				hasFilters: hasFilters(newState),
				showCount: initialSiteCount,
				showTotal: filteredSites.length,
			};
		}

		case TOGGLE_MAIN_COLOR: {
			const { selectedMainColor } = state;

			const newState = {
				...state,
				selectedMainColor: selectedMainColor === payload.color ? null : payload.color,
			};

			const filteredSites = getFilteredSites(newState);

			return {
				...newState,
				allVisible: isShowingAll(filteredSites.length),
				filteredSites,
				hasFilters: hasFilters(newState),
				showCount: initialSiteCount,
				showTotal: filteredSites.length,
			};
		}

		case LOAD_MORE:
			const { showCount, all, filteredSites } = state;
			const totalSites = filteredSites ? filteredSites.length : all.length;
			const loadCount = ~~payload.count || initialSiteCount;
			const newShowCount = Math.min(showCount + loadCount, totalSites);

			return {
				...state,
				showCount: newShowCount,
				...(newShowCount === totalSites && { allVisible: true }),
			};

		case TOGGLE_SORT: {
			return {
				...state,
				sort: payload.sort,
			};
		}

		case RESET_FILTERS: {
			const newState = {
				...state,
				allVisible: false,
				hasFilters: false,
				selectedMainColor: null,
				selectedTags: [],
				showCount: initialSiteCount,
				showTotal: null,
			};

			return {
				...newState,
				filteredSites: getFilteredSites(newState),
			};
		}

		default: {
			return state;
		}
	}
}
