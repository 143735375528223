import styled, { css } from "styled-components";

export const SiteContainer = styled.div`
	border-radius: 10px;
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
`;

export const Site = styled.article`
	cursor: pointer;
	pointer-events: all;
	position: relative;
	z-index: 1;

	&:hover {
		z-index: 3;

		.image-container {
			transform: scale(var(--scaleAmount));
		}

		.image {
			box-shadow: 0px 65px 120px -34px rgba(0, 0, 0, 0.5);

			&::after {
				opacity: 0.9;
			}
		}

		.shine,
		h3 {
			opacity: 1;
		}
	}
`;

export const SiteImageContainer = styled.div.attrs(() => ({
	className: "image-container",
}))`
	--scaleAmount: 1.05;
	--xRatio: 0.1;
	--yRatio: 0.06;

	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	transition: transform 300ms;
	z-index: 2;

	@media (min-width: 490px) {
		--scaleAmount: 1.1;
		--xRatio: 0.15;
		--yRatio: 0.1;
	}

	@media (min-width: 991px) {
		--scaleAmount: 1.2;
	}

	@media (min-width: 1200px) {
		--scaleAmount: 1.1;
		--xRatio: 0.1;
		--yRatio: 0.08;
	}

	@media (min-width: 2000px) {
		--scaleAmount: 1.1;
		--xRatio: 0.05;
		--yRatio: 0.02;
	}
`;

export const SiteImagePerspective = styled.div`
	transform-style: preserve-3d;
	transform: perspective(800px);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
`;

export const SiteImage = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	background-color: ${(props) => props.background};
	background-image: ${(props) => (props.image ? `url(${props.image})` : "none")};
	border-radius: 3px;
	box-shadow: 0px 15px 30px -11px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	transition: box-shadow 300ms;
	transform: rotateY(calc(var(--dx) * var(--yRatio) * -1deg)) rotateX(calc(var(--dy) * var(--xRatio) * 1deg))
		translate(calc(var(--tx) * 1px), calc(var(--ty) * 1px));
	width: 100%;

	&::after {
		background: var(--color-brand);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity 300ms;
		width: 100%;
		z-index: 1;
	}
`;

export const Shine = styled.span`
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	filter: blur(25px);
	left: 5%;
	opacity: 0;
	padding-top: 90%;
	pointer-events: none;
	position: absolute;
	top: -20%;
	transition: opacity 300ms;
	transform: translate(calc(var(--dx) * 1px), calc(var(--dy) * 1px));
	width: 90%;
	z-index: 9;
`;

export const SiteName = styled.h3`
	color: white;
	font-weight: bold;
	opacity: 0;
	padding: 0 15px;
	pointer-events: none;
	position: relative;
	text-align: center;
	transition: opacity 300ms;
	transform: translate3d(calc(var(--tx) / 1.5 * -1px), calc(var(--ty) / 1.5 * -1px), 0);
	z-index: 4;
`;
