export const hasWindow = typeof window !== "undefined" && !!window;

export const stripHash = (string) => {
	const [first, ...rest] = string.split("");
	if (first === "#") return rest.join("");
	return string;
};

export const ensureHash = (string) => {
	if (!string) return "";
	const [first] = string.split("");
	if (first !== "#") return "#" + string;
	return string;
};

export const collectionToArray = (c) => {
	if (!c) return [];

	if (Array.isArray(c)) return c;

	return Object.keys(c).map((key) => ({ ...c[key] }));
};
