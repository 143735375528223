import React from "react";
import { Colors, ColorButton } from "./styles";
import { mainColors } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { sitesActions } from "../../redux/sites";
import clsx from "clsx";

const MainColors = () => {
	const dispatch = useDispatch();
	const { selectedMainColor: selectedColor } = useSelector(store => store.sites);
	const toggleColor = color => dispatch(sitesActions.toggleMainColor(color));

	return (
		<Colors role="search" aria-label="Color search">
			{mainColors.map((color, index) => (
				<ColorButton
					className={clsx("main-color-button", selectedColor === color && "active")}
					key={"mclrbtn$$" + index}
					onClick={() => toggleColor(color)}
					style={{ backgroundColor: color.toLowerCase(), background: `conic-gradient(${color.toLowerCase()}, black)` }}
			>{color}</ColorButton>
			))}
		</Colors>
	);
};

export default MainColors;
