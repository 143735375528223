import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { ensureHash, stripHash } from "../../utils/common";
import SiteGrid from "../../components/SiteGrid";
import { Info } from "./styles";
import { Link } from "react-router-dom";
import { newestToOldest } from "../../utils/sort-fns";

export default withRouter((props) => {
	const color = get(props, ["match", "params", "color"]);
	const { all: sites } = useSelector((store) => store.sites);
	const colorSites = sites.filter((site) => {
		const colors = Object.values(site.colors).filter(Boolean).map(stripHash);
		return colors.includes(stripHash(color));
	});

	const sortedColorSites = colorSites.sort(newestToOldest);

	const colorHex = ensureHash(color);

	useEffect(() => {
		try {
			const scroll = get(props, "location.state.scroll");

			if (scroll && !!window) {
				window.scrollTo(0, 0);

				props.history.replace({
					pathname: props.location.pathname,
					state: { scroll: false },
				});
			}
		} catch (err) {
			console.warn(err);
		}
	}, []);

	{/* <div style={{ padding: "0 calc(10px + 3%)" }}> */}

	return (
		<div>
			<Helmet>
				<title>Sites that use the exact color {colorHex} - CSSline</title>
			</Helmet>

			<Info>
				<h1>
					Sites that use the <i>exact</i> color <i>{colorHex}</i> 👍
				</h1>
				<Link to="/" className="link">Go to Index</Link>
				<div className="marquee">
					<div className="marquee__inner" aria-hidden="true">
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
						<span>Go to Index 👉</span>
					</div>
				</div>
			</Info>

			<SiteGrid sites={sortedColorSites} />
		</div>
	);
});
