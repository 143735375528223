import React from "react";
import { LoadMoreContainer, LoadButton } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { sitesActions } from "../../redux/sites";

const LoadMore = () => {
	const dispatch = useDispatch();
	const { allVisible, showCount, showTotal, all } = useSelector((store) => store.sites);
	const total = showTotal || all.length;
	const loadMore = () => dispatch(sitesActions.loadMore());

	if (allVisible) return null;

	return (
		<LoadMoreContainer>
			<LoadButton onClick={loadMore}>
				Load more ({showCount} / {total})
			</LoadButton>
		</LoadMoreContainer>
	);
};

export default LoadMore;
