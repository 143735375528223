import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import { Info } from "./styles";
import { Link } from "react-router-dom";

const AboutPage = (props) => {

	useEffect(() => {
		try {
			const scroll = get(props, "location.state.scroll");

			if (scroll && !!window) {
				window.scrollTo(0, 0);

				props.history.replace({
					pathname: props.location.pathname,
					state: { scroll: false },
				});
			}

			else {
				window.scrollTo(0, 0);
			}

		} catch (err) {
			console.warn(err);
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>About CSSline - CSSline Showcase Gallery</title>
			</Helmet>

			<Info>
				<h1>
					About CSSline 📜
				</h1>

				<h2>First version of CSSline (2009)</h2>

				<p>First version was born in 2009 when <a href="https://www.instagram.com/virlander/" className="link" target="_blank" rel="noreferrer noopener">@virlander</a> wasn't satisfied with the websites that CSS showcase sites like <a href="http://web.archive.org/web/20090308221924/http://www.cssbeauty.com/?ref=cssline.com" className="link" target="_blank" rel="noreferrer noopener"><strike>CSS Beauty</strike></a> ⚱ and <a href="http://web.archive.org/web/20050207031200/http://www.stylegala.com/?ref=cssline.com" className="link" target="_blank" rel="noreferrer noopener"><strike>Stylegala</strike></a> ⚱ provided.</p>

				<p><a href="https://www.instagram.com/virlander/" className="link" target="_blank" rel="noreferrer noopener">@virlander</a> teamed with <a href="https://www.instagram.com/jyrki/" className="link" target="_blank" rel="noreferrer noopener">@jyrki</a> (both of them worked as developers at <a href="https://valve.fi?ref=cssline.com" className="link" target="_blank" rel="noreferrer noopener">Valve</a>) to create the first version of CSSline that was running on a custom frontend (jQuery 🤔) and custom backend (CodeIgniter 😎) hosted by <strike>Slicehost</strike> Rackspace.</p>

				{/*<p>We actually sold 🤑🤑🤑 our backend code to couple of clients, thanks to our sales manager Aki Snellman.</p>*/}

				<p>CSSline custom logo was done by <a href="https://www.instagram.com/mufacik/" className="link" target="_blank" rel="noreferrer noopener">@mufacik</a> &amp; <a href="https://www.junnu.fi/?ref=cssline.com" className="link" target="_blank" rel="noreferrer noopener">Junnu.fi</a>.</p>

				<p>In 2012 <a href="https://www.instagram.com/nivaladesign/" className="link" target="_blank" rel="noreferrer noopener">@nivaladesign</a> created a new design for CSSline but for some strange reason we didn't have never time to actually code that version.</p>

				{/*<p>When Jyrki left <a href="https://valve.fi" className="link" target="_blank" rel="noreferrer noopener">Valve</a> and joined <a href="https://www.reaktor.com/" className="link" target="_blank" rel="noreferrer noopener">Reaktor</a> Vesa was left to keep the site up and running and approve site submissions.</p>

				<p>There was couple of unreleased versions of new CSSline but Vesa was never fully satisfied with them and didn't publish them.</p>*/}

				<h2>Latest version of CSSline (2020)</h2>
				<p>When <a href="https://www.instagram.com/teemumeet/" className="link" target="_blank" rel="noreferrer noopener">@teemumeet</a> joined Valve, <a href="https://www.instagram.com/virlander/" className="link" target="_blank" rel="noreferrer noopener">@virlander</a> showcased CSSline to him (<a href="https://www.instagram.com/jyrki/" className="link" target="_blank" rel="noreferrer noopener">@jyrki</a> had gone to <a href="https://www.reaktor.com/?ref=cssline.com" className="link" target="_blank" rel="noreferrer noopener">Reaktor</a> earlier) and they started to develop a new version of CSSline using <strike>Angular.js</strike> <strike>Angular 4</strike> <strike>React</strike> <strike>React+Next.js</strike> React+Razzle (Nodejs, Razzle, React, Redux, Styled-components + Firebase) in <strike>2016</strike> <strike>2017</strike> <strike>2018</strike> <strike>2019</strike> 2020.</p>

				<div style={{ position: "relative" }}>
					<Link to="/" className="link">Go to front page</Link> 👉
					<div className="marquee">
					<div className="marquee__inner" aria-hidden="true">
						<span>Go to front page 👉</span>
						<span>Go to front page 👉</span>
						<span>Go to front page 👉</span>
						<span>Go to front page 👉</span>
					</div>
				</div>
				</div>
			</Info>

		</div>
	);
};

export default withRouter(AboutPage);
