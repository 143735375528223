import styled from "styled-components";

export const GoSiteContainer = styled.div`
	padding: 0 var(--page-padding);
	position: relative;
	pointer-events: none;
	z-index: 2;

	> section {
		align-items: center;
		display: block;
		grid-template-columns: repeat(12,minmax(0,1fr));
		margin: .5em 0 1em;

		&.align-items-end {
			align-items: end;
		}
		&.align-items-start {
			align-items: start;
		}

		@media screen and (min-width: 600px) {
			display: grid;
		}

		> * {
			margin: 0;
			-webkit-font-smoothing: subpixel-antialiased;
			-webkit-font-smoothing: antialiased;
		}
	}

	h1 {
		font-size: 1.5rem;

		a {
			/*text-decoration: none;*/
		}
	}

	h1,p,ul,div {
		grid-column: span 9/span 9
	}

	h2 {
		font-size: .9rem;
		font-weight: 400;
		grid-column: span 3/span 3;
	}

	a {
		pointer-events: all;
	}
`;

export const GoColors = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: -20px -20px 20px -20px !important;
	padding: 10px;
	padding: calc(.5em + 20px) 10px 10px 10px;

	@media (min-width: 1000px) {
		max-width: calc(80vw + 80px);
	}
`;

export const ColorContainer = styled.span`
	background: var(--color-bg);
	box-shadow: 0 2px 4px -2px rgba(36, 45, 50, 0.2);
	border: 1px solid var(--color-go-page-color-border);
	cursor: pointer;
	display: inline-block;
	flex: 1 1 120px;
	font-size: 0;
	margin: 10px;
	text-decoration: none;
	transform: translateY(0);
	transition: box-shadow 300ms cubic-bezier(0.65, -1, 0.25, 2.5), transform 300ms cubic-bezier(0.65, -1, 0.25, 2.5);
	will-change: transform, box-shadow;

	&:hover {
		box-shadow: var(--color-go-page-color-shadow-hover);
		transform: translateY(-0.3rem);
	}

	.color-bg {
		border-bottom: 1px solid var(--color-go-page-color-border);
		display: block;
		padding-bottom: 46%;
		width: 100%;
	}

	.color-text {
		color: var(--color-text);
		font-size: 14px;
		display: block;
		padding: 10px;
	}
`;

export const Screenshot = styled.div`
	background: red;
	margin: .5em 0 1em !important;
	overflow: hidden;
	/*padding: 60px 5%;
	perspective: 6000px;*/
	transform: translateY(0);
	transition: box-shadow 300ms cubic-bezier(0.65, -1, 0.25, 2.5), transform 300ms cubic-bezier(0.65, -1, 0.25, 2.5);
	will-change: transform;

	@media (min-width: 1000px) {
		max-width: calc(80vw + 40px);
	}

	&:hover {
		box-shadow: 0 10px 16px -4px rgba(36, 45, 50, 0.3);
		transform: translateY(-0.3rem);
	}

	/*
	.glare-wrapper {
		border-radius: 6px;
		overflow: hidden;
	}
	*/

	div {
	}

	img {
		display: block;
		box-shadow: 0 20px 80px -35px rgba(0, 0, 0, 0.6);
		box-shadow: 0px 20px 40px -55px rgba(0, 0, 0, 0.6);
		margin: 0 auto;
		width: 90%;

		&[src=""] {
			display: none;
		}
	}
`;

export const GoTags = styled.div`
	padding: .5em 0 0 0;

	a,
	span {
		background: var(--color-tag-button-bg);
		border: 2px solid var(--color-tag-button-border);
		border-radius: 0.125rem;
		box-shadow: 1px 1px 0 var(--color-tag-button-border), 2px 2px 0 var(--color-tag-button-border);
		cursor: pointer;
		color: var(--color-tag-button-text);
		display: inline-table;
		font-size: 0.75em;
		margin-bottom: 10px;
		padding: 0.2rem 0.5rem;
		text-decoration: none;
		transition: transform 0.1s;
		white-space: nowrap;

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		&.active,
		&:hover {
			box-shadow: 0 0 0 var(--color-tag-button-border), 0 0 0 var(--color-tag-button-border);
			transform: translateY(2px);
		}
	}
`;

export const GoNav = styled.div`
	padding: 0 var(--page-padding);
	position: relative;
	z-index: 999;

	h2 {
		font-size: .9rem;
		font-weight: 400;
		margin: 2em 0 1em;
	}

	.GoNavContainer {

		@media (min-width: 900px) {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		div + div {
			font-size: .9rem;
			font-weight: 400;
			padding-top: 1em;

			@media (min-width: 900px) {
				order: -1;
				padding: 0 1em 0 0;
			}
		}

		div {

			img {
				left: 100%;
				max-width: 50vw;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				top: -100%;
				transform: translate3d(calc(-100% - 6vw),-30%,0) translate3d(0,20px,0);
			}

			a:hover + img {

				@media (min-width: 900px) {
					opacity: 1;
					transform: translate3d(calc(-100% - 6vw),-30%,0) rotate3d(0,0,1,4deg);
					transition: all 0.4s;
				}
			}

			/*

			&.next-site {

				img {
					right: 100%;
					transform: translate3d(calc(-200% - 6vw),-30%,0) translate3d(0,20px,0);
				}

				a:hover + img {
					transform: translate3d(calc(-200% - 6vw),-30%,0) rotate3d(0,0,1,-4deg);
				}
			}

			*/

			.marquee {
				display: none;
				left: 0;
				mix-blend-mode: color-burn;
				overflow: hidden;
				pointer-events: none;
				position: absolute;
				top: 0;
				width: 98vw;

				@media (min-width: 900px) {
					/*display: block;*/
				}

				.marquee__inner {
					animation: marquee 5s linear infinite;
					animation-play-state: paused;
					display: flex;
					opacity: 0;
					position: relative;
					transform: translate3d(calc(-25% + 20vw), 0, 0);
					transition: opacity 0.1s;
					width: fit-content;

					span {
						display: inline-block;
						font-size: 10vw;
						font-style: italic;
						font-weight: 900;
						line-height: 1.15;
						padding: 0 3vw;
						white-space: nowrap;
					}
				}
			}

			a:hover ~ .marquee {
				display: block;
			}

			a:hover ~ .marquee .marquee__inner {
				animation-play-state: running;
				opacity: 1;
				transition-duration: 0.4s;
			}

			@keyframes marquee {
				0% {
					transform: translate3d(calc(-25% + 20vw), 0, 0);
				}

				100% {
					transform: translate3d(calc(-50% + 20vw), 0, 0);
				}
			}
		}
	}

	.no-site {

	}
`;
