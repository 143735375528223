import React from "react";
import { Helmet } from "react-helmet-async";

const GoHelmet = ({ site }) => {
	return (
		<Helmet>
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@cssline" />
			<meta name="twitter:title" content={site.title} />
			<meta name="twitter:description" content={`This time we have added a site called ${site.title}. Come and see details of it!`} />
			<meta name="twitter:image" content={site.screenshotUrl} />
			<meta property="fb:app_id" content="3515925741778167" />
			<meta property="og:type" content="article" />
			<meta property="og:site_name" content="CSSline" />
			<meta property="og:title" content={site.title} />
			<meta property="og:description" content={`This time we have added a site called ${site.title}. Come and see the details of it!`} />
			<meta property="og:image" content={site.screenshotUrl} />
			<meta property="og:image:height" content="900" />
			<meta property="og:image:width" content="1600" />
			<meta property="og:url" content={`https://cssline.com/go/${site.goUrl}`} />

			<link rel="canonical" href={`https://cssline.com/go/${site.goUrl}`}></link>
			{site?.title && <title>{site?.title} in the CSSline spotlight</title>}
		</Helmet>
	);
};

export default GoHelmet;
