import styled from "styled-components";

export const Footer = styled.footer`
	clear: both;
	font-size: 14px;
	padding: 4em var(--page-padding);
	pointer-events: all;
	text-align: center;

	p {
		line-height: 1.6;

		span {
			display: inline-block;
			padding: 0 10px 0 5px;
		}

	}
`;

export const FooterLinks = styled.ul`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	font-size: 14px;
	margin: 0 0 2em;
	padding: 4em var(--page-padding) 0;
	text-align: center;

	@media (min-width: 330px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 490px) {
		display: flex;
		justify-content: center;
	}

	li {
		list-style: none;
		padding-bottom: 1em;

		+ li {
			padding-left: 1em;
		}
	}
`;
