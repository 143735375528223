import styled from "styled-components";

export const ThemeSwitch = styled.label`
	display: flex;
	justify-content: space-between;
	padding: 0 0 .5em;

	i {
		background: #d8d9db;
		border-radius: 13px;
		box-sizing: initial;
		display: inline-block;
		height: 20px;
		margin: 0 0 0 1em;
		min-width: 40px;
		padding: 2px;
		position: relative;
		transition: .25s .09s;
		vertical-align: middle;
		width: 40px;

		&:after {
			background: #fff;
			border-radius: 50%;
			content: " ";
			display: block;
			height: 20px;
			left: 2px;
			min-width: 20px;
			position: absolute;
			transition: .25s;
			width: 20px;
		}
	}

	&.is-dark-mode i {
		background: #4bd865;
		background: var(--color-brand);
	}

	&.is-light-mode i {
		background: repeating-radial-gradient() !important;
	}

	&.is-dark-mode i:after {
		transform: translateX(20px);
	}

	&.is-light-mode i:after {
		transform: translateX(0px);
	}

	&:hover {
		cursor: pointer;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}
`;
