import { LOAD_MORE, RESET_FILTERS, TOGGLE_MAIN_COLOR, TOGGLE_SORT, TOGGLE_TAG } from "./types";

export const toggleTag = (tag) => ({
	type: TOGGLE_TAG,
	payload: { tag },
});

export const toggleMainColor = (color) => ({
	type: TOGGLE_MAIN_COLOR,
	payload: { color },
});

export const loadMore = (count) => ({
	type: LOAD_MORE,
	payload: { count },
});

export const toggleSort = (sort) => ({
	type: TOGGLE_SORT,
	payload: { sort },
});

export const resetFilters = () => ({
	type: RESET_FILTERS,
	payload: null,
});
