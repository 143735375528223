import React, { useEffect } from "react";
import { ensureHash, stripHash } from "../../utils/common";
import { useSelector } from "react-redux";
import { GoSiteContainer, GoColors, ColorContainer, Screenshot, GoTags, GoNav } from "./styles";
import { withRouter, Link, Redirect } from "react-router-dom";
import GoHelmet from "./helmet";
import { sortOptions } from "../../utils/config";
import ErrorBoundary from "../../components/ErrorBoundary";

function Tags({ tags, history }) {
	if (!tags || !tags.length) return null;

	const navigateToTag = (tag) => {
		history.push({
			pathname: `/tag/${tag}`,
			state: { scroll: true },
		});
	};

	return (
		<GoTags>
			{tags.sort().map((tag, i) => (
				<span key={"sitetag" + tag + "$$" + i} className="js-events tagName" onClick={() => navigateToTag(tag)}>
					{tag}
				</span>
			))}
		</GoTags>
	);
}

function Colors({ colors, history }) {
	if (!colors) return null;

	const navigateToColor = (color) => {
		history.push({
			pathname: `/color/${color}`,
			state: { scroll: true },
		});
	};

	return (
		<GoColors>
			{colors.map((color, idx) => {
				return (
					<ColorContainer
						className="js-events color-container"
						key={"site-color" + color + "$$" + idx}
						onClick={() => navigateToColor(stripHash(color))}
					>
						<span className="color-bg" style={{ backgroundColor: ensureHash(color) }} />
						<span className="color-text">{ensureHash(color)}</span>
					</ColorContainer>
				);
			})}
		</GoColors>
	);
}

function PrevSite({ site }) {
	if (!site) return <div className="no-site">Previous site ✋ No more sites 😭</div>;

	return (
		<div className="prev-site">
			Previous site 👉 <Link to={`/go/${site.goUrl}`} className="link">{site.title}</Link>
			<img src={site.thumbUrl} alt={site.title} />
			<div className="marquee">
				<div className="marquee__inner" aria-hidden="true">
					<span>{site.title}</span>
					<span>{site.title}</span>
					<span>{site.title}</span>
					<span>{site.title}</span>
				</div>
			</div>
		</div>
	);
}

function NextSite({ site }) {
	if (!site) return <div className="no-site">Next site ✋ No more sites 😭</div>;

	return (
		<div className="next-site">
			Next site 👉 <Link to={`/go/${site.goUrl}`} className="link">{site.title}</Link>
			<img src={site.thumbUrl} alt={site.title} />
			<div className="marquee">
				<div className="marquee__inner" aria-hidden="true">
					<span>{site.title}</span>
					<span>{site.title}</span>
					<span>{site.title}</span>
					<span>{site.title}</span>
				</div>
			</div>
		</div>
	);
}

const getPrevAndNext = (goUrl, all) => {
	const siteIndex = all?.findIndex((site) => site?.goUrl === goUrl);

	if (siteIndex === -1) {
		return {
			prev: null,
			next: null,
		};
	}

	const prev = siteIndex === 0 ? null : all[siteIndex - 1];
	const next = siteIndex === all.length - 1 ? null : all[siteIndex + 1];

	return {
		prev,
		next,
	};
};

const GoSite = (props) => {
	const { all, filteredSites = [], sort, hasFilters } = useSelector((store) => store.sites);
	const goUrl = props?.match?.params?.goUrl;
	const site = all?.filter((site) => site.goUrl === goUrl)[0];
	const { sortFn } = sortOptions.find((option) => option.label === sort) || {};
	const sites = hasFilters ? filteredSites : all;
	const sortedSites = [...sites].sort(sortFn).reverse();
	const { prev, next } = getPrevAndNext(goUrl, sortedSites);

	useEffect(() => {
		if (!!window && typeof window === "object") {
			window.scrollTo(0, 0);
		}
	}, []);

	if (!site) return <Redirect to={"/404"} />;

	let url = new URL(site?.url);
	let hostname = url.hostname;
	let pathname = url.pathname;

	// Remove www. if it exists
	if (hostname.startsWith('www.')) {
		hostname = hostname.slice(4);
	}

	// Remove trailing slash if it exists
	if (pathname.endsWith('/')) {
		pathname = pathname.slice(0, -1);
	}

	return (
		<ErrorBoundary fallback={<Redirect to={"/404"} />}>
			<GoHelmet site={site} />
			<GoSiteContainer>
				<section className="name">
					<h2>Name</h2>
					<h1>{site?.title}</h1>
				</section>

				<section className="url">
					<h2>Url</h2>
					<p>
						<a href={site?.url + "?ref=cssline.com"} className="link ellipsis" target="_blank" rel="noreferrer noopener">
							{/*hostname + pathname*/}
							{site?.url}
						</a>
					</p>
				</section>

				<section className="tags">
					<h2>Tags</h2>
					<Tags tags={site?.tags} history={props.history} />
				</section>

				<section className="colors">
					<h2>Colors</h2>
					<Colors colors={site?.colors} history={props.history} />
				</section>

				<section className="image">
					<h2>Image</h2>
					<Screenshot style={{ backgroundColor: ensureHash(site?.colors?.[0]) }}>
						<div
							style={{
								position: "relative",
								paddingBottom: "60.25%",
								width: "100%",
								margin: "auto",
								overflow: "hidden",
							}}
						>
							<a href={site?.url + "?ref=cssline.com"} target="_blank" rel="noreferrer noopener">
								<img
									src={site?.screenshotUrl}
									alt={site?.title}
									style={{
										bottom: 0,
										height: "100%",
										left: 0,
										margin: "auto",
										position: "absolute",
										right: 0,
										top: 0,
										width: "100%",
									}}
								/>
							</a>
						</div>
					</Screenshot>
				</section>
			</GoSiteContainer>

			<GoNav>
				<h2>Browse more sites 😎</h2>
				<div className="GoNavContainer">
					<NextSite site={next} />
					<PrevSite site={prev} />
				</div>
			</GoNav>
		</ErrorBoundary>
	);
};

export default withRouter(GoSite);
